import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5e65fa80 = () => interopDefault(import('../pages/theme-2/cashier.vue' /* webpackChunkName: "pages/theme-2/cashier" */))
const _6825db5e = () => interopDefault(import('../pages/theme-2/download/index.vue' /* webpackChunkName: "pages/theme-2/download/index" */))
const _1e68dbc2 = () => interopDefault(import('../pages/theme-2/forgot-password.vue' /* webpackChunkName: "pages/theme-2/forgot-password" */))
const _602478c1 = () => interopDefault(import('../pages/theme-2/games/index.vue' /* webpackChunkName: "pages/theme-2/games/index" */))
const _04bfbd34 = () => interopDefault(import('../pages/theme-2/login.vue' /* webpackChunkName: "pages/theme-2/login" */))
const _17d41814 = () => interopDefault(import('../pages/theme-2/my-bonus/index.vue' /* webpackChunkName: "pages/theme-2/my-bonus/index" */))
const _9b2ef7d4 = () => interopDefault(import('../pages/theme-2/no-support.vue' /* webpackChunkName: "pages/theme-2/no-support" */))
const _a62a07f6 = () => interopDefault(import('../pages/theme-2/not-found.vue' /* webpackChunkName: "pages/theme-2/not-found" */))
const _2d012baa = () => interopDefault(import('../pages/theme-2/payment-methods/index.vue' /* webpackChunkName: "pages/theme-2/payment-methods/index" */))
const _f4722d30 = () => interopDefault(import('../pages/theme-2/play-game.vue' /* webpackChunkName: "pages/theme-2/play-game" */))
const _ea7b94ba = () => interopDefault(import('../pages/theme-2/promotion/index.vue' /* webpackChunkName: "pages/theme-2/promotion/index" */))
const _b8b963be = () => interopDefault(import('../pages/theme-2/refer-friend/index.vue' /* webpackChunkName: "pages/theme-2/refer-friend/index" */))
const _6bdfb5b6 = () => interopDefault(import('../pages/theme-2/register.vue' /* webpackChunkName: "pages/theme-2/register" */))
const _249f9aa2 = () => interopDefault(import('../pages/theme-2/reset-password/index.vue' /* webpackChunkName: "pages/theme-2/reset-password/index" */))
const _1cde657b = () => interopDefault(import('../pages/theme-2/download/android.vue' /* webpackChunkName: "pages/theme-2/download/android" */))
const _08c43bce = () => interopDefault(import('../pages/theme-2/download/ios.vue' /* webpackChunkName: "pages/theme-2/download/ios" */))
const _e290fdc0 = () => interopDefault(import('../pages/theme-2/history/game.vue' /* webpackChunkName: "pages/theme-2/history/game" */))
const _17210620 = () => interopDefault(import('../pages/theme-2/history/transaction.vue' /* webpackChunkName: "pages/theme-2/history/transaction" */))
const _73287f1f = () => interopDefault(import('../pages/theme-2/payment-methods/add-bank.vue' /* webpackChunkName: "pages/theme-2/payment-methods/add-bank" */))
const _fab02a9c = () => interopDefault(import('../pages/theme-2/payment-methods/add-crypto-wallet.vue' /* webpackChunkName: "pages/theme-2/payment-methods/add-crypto-wallet" */))
const _f5443b7a = () => interopDefault(import('../pages/theme-2/player/deposits/index.vue' /* webpackChunkName: "pages/theme-2/player/deposits/index" */))
const _489b2d3a = () => interopDefault(import('../pages/theme-2/player/messages/index.vue' /* webpackChunkName: "pages/theme-2/player/messages/index" */))
const _7c91234e = () => interopDefault(import('../pages/theme-2/player/profile.vue' /* webpackChunkName: "pages/theme-2/player/profile" */))
const _65c68974 = () => interopDefault(import('../pages/theme-2/player/withdrawals/index.vue' /* webpackChunkName: "pages/theme-2/player/withdrawals/index" */))
const _10c9be70 = () => interopDefault(import('../pages/theme-2/promotion/bonus.vue' /* webpackChunkName: "pages/theme-2/promotion/bonus" */))
const _33f87e4f = () => interopDefault(import('../pages/theme-2/index.vue' /* webpackChunkName: "pages/theme-2/index" */))
const _9e074db6 = () => interopDefault(import('../pages/theme-2/payment-methods/edit/bank/_id.vue' /* webpackChunkName: "pages/theme-2/payment-methods/edit/bank/_id" */))
const _4714dd2a = () => interopDefault(import('../pages/theme-2/payment-methods/edit/crypto/_id.vue' /* webpackChunkName: "pages/theme-2/payment-methods/edit/crypto/_id" */))
const _38d1766a = () => interopDefault(import('../pages/theme-2/promotion/_id.vue' /* webpackChunkName: "pages/theme-2/promotion/_id" */))
const _1948993a = () => interopDefault(import('../pages/theme-2/articles/_title/_slug.vue' /* webpackChunkName: "pages/theme-2/articles/_title/_slug" */))
const _def6167e = () => interopDefault(import('../pages/theme-2/_type/_id.vue' /* webpackChunkName: "pages/theme-2/_type/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cashier",
    component: _5e65fa80,
    name: "cashier"
  }, {
    path: "/download",
    component: _6825db5e,
    name: "download"
  }, {
    path: "/forgot-password",
    component: _1e68dbc2,
    name: "forgot-password"
  }, {
    path: "/games",
    component: _602478c1,
    name: "games"
  }, {
    path: "/login",
    component: _04bfbd34,
    name: "login"
  }, {
    path: "/my-bonus",
    component: _17d41814,
    name: "my-bonus"
  }, {
    path: "/no-support",
    component: _9b2ef7d4,
    name: "no-support"
  }, {
    path: "/not-found",
    component: _a62a07f6,
    name: "not-found"
  }, {
    path: "/payment-methods",
    component: _2d012baa,
    name: "payment-methods"
  }, {
    path: "/play-game",
    component: _f4722d30,
    name: "play-game"
  }, {
    path: "/promotion",
    component: _ea7b94ba,
    name: "promotion"
  }, {
    path: "/refer-friend",
    component: _b8b963be,
    name: "refer-friend"
  }, {
    path: "/register",
    component: _6bdfb5b6,
    name: "register"
  }, {
    path: "/reset-password",
    component: _249f9aa2,
    name: "reset-password"
  }, {
    path: "/download/android",
    component: _1cde657b,
    name: "download-android"
  }, {
    path: "/download/ios",
    component: _08c43bce,
    name: "download-ios"
  }, {
    path: "/history/game",
    component: _e290fdc0,
    name: "history-game"
  }, {
    path: "/history/transaction",
    component: _17210620,
    name: "history-transaction"
  }, {
    path: "/payment-methods/add-bank",
    component: _73287f1f,
    name: "payment-methods-add-bank"
  }, {
    path: "/payment-methods/add-crypto-wallet",
    component: _fab02a9c,
    name: "payment-methods-add-crypto-wallet"
  }, {
    path: "/player/deposits",
    component: _f5443b7a,
    name: "player-deposits"
  }, {
    path: "/player/messages",
    component: _489b2d3a,
    name: "player-messages"
  }, {
    path: "/player/profile",
    component: _7c91234e,
    name: "player-profile"
  }, {
    path: "/player/withdrawals",
    component: _65c68974,
    name: "player-withdrawals"
  }, {
    path: "/promotion/bonus",
    component: _10c9be70,
    name: "promotion-bonus"
  }, {
    path: "/",
    component: _33f87e4f,
    name: "index"
  }, {
    path: "/payment-methods/edit/bank/:id?",
    component: _9e074db6,
    name: "payment-methods-edit-bank-id"
  }, {
    path: "/payment-methods/edit/crypto/:id?",
    component: _4714dd2a,
    name: "payment-methods-edit-crypto-id"
  }, {
    path: "/promotion/:id",
    component: _38d1766a,
    name: "promotion-id"
  }, {
    path: "/articles/:title?/:slug?",
    component: _1948993a,
    name: "articles-title-slug"
  }, {
    path: "/:type/:id?",
    component: _def6167e,
    name: "type-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
